// Instalação do Tailwind CSS
@tailwind base;
@tailwind components;
@tailwind utilities;


/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito' !important;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none !important;
  color: #000;
}

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-wrapper);
  height: 100vh;
}

.content-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 40px;
}

.content-body {
  width: 100%;
  max-height: calc(100vh - 240px);
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.input-space {
  margin-bottom: 15px;
}

.input-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #bdbdbd;
  border-radius: 0.5rem !important;
  transition: border-color 0.3s ease;
  background: #fff;
  resize: none;

  &:focus {
    outline: none;
    border-color: #4213f6;
  }

}


@import "../src/assets/css/core/sidebar";
@import "../src/assets/css/core/login";
@import "../src/assets/css/core/header";
@import "../src/assets/css/core/pages";
@import "../src/assets/css/core/components";

//MATERIAL
.mdc-line-ripple::before {
  content: none !important;
}

.mat-mdc-card-content {
  display: flex;
  padding: 0 16px;
}

.input-bg-color {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: none;
}

.all-unset {
  all: unset;
}

input[type="radio"]+label span {
  transition: background .2s,
    transform .2s;
}

input[type="radio"]+label span:hover,
input[type="radio"]+label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked+label span {
  background-color: #3490DC; //bg-blue
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked+label {
  color: #3490DC; //text-blue
}

// Estilo global para o filtro em SCSS equivalente ao Tailwind CSS

.global-filter {
  border: 1px solid #BDBDBD;
  border-radius: 0.5rem;
}

.shadow-global {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

// ::-webkit-scrollbar
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4PX;
}

::-webkit-scrollbar-thumb {
  background-color: #c0bfc2;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c0bfc2;
}
