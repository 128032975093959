.sidebar-container{
  height: 100vh;
  width: 300px;
  background-color: var(--brand-dark-blue);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;

  .logo-sidebar{
    width: 179px;
    height: 73px;
    background-image: url('../../../assets/images/logo-sidebar.png');
    background-repeat: no-repeat;
    margin-top: 24px;
    margin-bottom: 30px;
  }

  .menu-contaiber{
    width: 100%;
    height: 80%;
    overflow: auto;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding-bottom: 20px;

  }

  .menu-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 18px;
    height: 25px;
    text-decoration: none;
  }
  .icon{
    display: flex;
    align-items: center;

    width: 30px;
    height: 25px;
  }
  span{
    font-weight: 600;
    color: #fff;
    font-size: 16px;
  }

  .footer-sidebar{
    width: 100%;
    height: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
  }
}

