//BEGIN CARD ICON
.card-icon-wrapper {
  width: 295px;
  height: 150px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
  -webkit-box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
  -moz-box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
}

.mat-drawer-container{
  background-color: #FCFCFD !important;
}

.card-icon-wrapper:hover{
  box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
  -webkit-box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
  -moz-box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
}

.card-icon-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icon-30{
  width: 30px !important;
  height: 30px !important;
  font-size: 30px !important;
}

.icon-20{
  width: 20px !important;
  height: 20px !important;
  font-size: 20px !important;
}

.icon-10{
  width: 10px !important;
  height: 10px !important;
  font-size: 10px !important;
}

.card-title{
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
}
//END CARD ICON

//BEGIN CARD SOCIAL
.card-icon-social-wrapper {
  width: 290px;
  height: 100px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
  -webkit-box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
  -moz-box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
}

.card-icon-social-wrapper:hover{
  box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
  -webkit-box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
  -moz-box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
}

.card-icon-social-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
//END CARD SOCIAL

//BEGIN CARD GRAPHIC SMALL
.card-graphic-small-wrapper {
  width: 270px;
  min-height: 85px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
  -webkit-box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
  -moz-box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
  margin-bottom: 10px;
}

.card-graphic-small-wrapper:hover{
  box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
  -webkit-box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
  -moz-box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
}

.card-graphic-small-content{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.card-total{
  font-weight: 900;
  font-size: 26px;
  text-transform: uppercase;
}

.conatiner-graphic-small{
  width: 230px;
  height: 280px;
  margin-top: 10px;
}

//END CARD GRAPHIC

//BEGIN CARD GRAPHIC MEDIUM
.card-graphic-medium-wrapper {
  width: 540px;
  min-height: 305px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  box-shadow: 0px 7px 18px -3px rgba(150, 130, 130, 0.75);
  -webkit-box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
  -moz-box-shadow: 0px 7px 18px -3px rgba(196,196,196,0.75);
}

.card-graphic-medium-wrapper:hover{
  box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
  -webkit-box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
  -moz-box-shadow: 0px 7px 18px -3px rgba(59,59,59,0.75);
}

.card-graphic-medium-content{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.card-total{
  font-weight: 900;
  font-size: 26px;
  text-transform: uppercase;
}

.conatiner-graphic-medium{
  width: 230px;
  height: 280px;
  margin-top: 10px;
  padding-left: -20px;
}

//END CARD GRAPHIC

//BEGIN CARD USERS
.card-users-wrapper {
  width: 50%;
  min-height: 285px;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

.card-users-content{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.card-img-user{
  height: 80px;
  width: 80px;
  border-radius: 50%;
  padding: 5px;
  background-color: white;
  object-fit: cover;
}

.container-users-img .card-img-user:not(:first-child){
  margin-left: -30px;
}

.button-more-users{
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background-color: var(--secondary-blue);
  color: white;
  border: none;
  margin-left: 20px;
}

// END CARD USERS

.title-modal{
  font-weight: 900 !important;
  font-size: 22px !important;
}

.mat-mdc-card-content {
  display: flex !important;
  padding: 0 16px;
}

// INPUT
.input-form {
  /* Input */
  
    box-sizing: border-box;
  
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;
  
    width: 443px;
    height: 40px;
  
    /* 🌝 Backgrounds/01 */
    background: #FFFFFF;
    /* 🌝 Text&Icons / Tertiary */
    border: 1px solid #BDBDBD;
    border-radius: 8px;
  
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

