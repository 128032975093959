.header-page{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

}



